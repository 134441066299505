import { createContext, useContext, useState } from 'react';

type State = { message: string | undefined };

const initialState: State = { message: undefined };

const context = createContext<any>(initialState);

export const useAlerts = () => {
  const [state, setState] = useContext(context);

  return {
    alertsStore: state,
    alertsActions: {
      addMessage: (message: string) => setState({ message }),
      removeMessage: () => setState({ message: undefined }),
    },
  };
};

export const AlertsStoreContainer = ({ children, initial }: any) => {
  const [state, setState] = useState<State>({ message: undefined });

  return (
    <context.Provider value={[state, setState]}>{children}</context.Provider>
  );
};
