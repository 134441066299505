import { text } from 'stream/consumers';
import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  colors: {
    theme: {
      primary: {
        default: '#FF6622',
        dark: '#CC521B',
        light: '#FF854E',
        soft: '#FFE0D3',
      },
      secondary: {
        default: '#1C75BC',
        dark: '#165E96',
        light: '#4991C9',
        soft: '#D2E3F2',
      },
      success: {
        default: '#36B37E',
        dark: '#2B8F65',
        light: '#5EC298',
        soft: '#D7F0E5',
      },
      danger: {
        default: '#DC3545',
        dark: '#B02A37',
        light: '#E35D6A',
        soft: '#F8D7DA',
      },
      warning: {
        default: '#FFBC33',
        dark: '#FFAB00',
        light: '#FFCD66',
        soft: '#FFEECC',
      },
      info: {
        default: '#0DDAFF',
        dark: '#0AAECC',
        light: '#3DE1FF',
        soft: '#CFF8FF',
      },
      neutralLight: {
        default: '#E9ECEF',
        dark: '#DEE2E6',
        light: 'FFFFFF',
        soft: '#F8F9FA',
      },
      neutralDark: {
        default: '#343A40',
        light: '#495057',
        dark: '#212529',
        soft: '#CED4DA  ',
      },
    },
    text: {
      primary: '#FF6622',
      dark: '#343A40',
      danger: '#DC3545',
      light: '#ADB5BD',
      secondary: '#1C75BC',
      success: '#36B37E',
      warning: '#FFAB00',
      info: '#0DDAFF',
      default: '#6C757D',
      black: '#212529',
      white: '#FFFFFF',
      black50: '#0B0B0B',
      white50: '#FFFFFF',
    },
  },
};
