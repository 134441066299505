import { createContext, useContext, useReducer, useState } from 'react';
import {
  createStore,
  Action,
  createContainer,
  createHook,
  createSubscriber,
} from 'react-sweet-state';

interface CartItem {
  itemUId: string;
  quantity: number;
}

type State = { items: string[] };

// type Actions = typeof actions;

const initialState: State = {
  items: [],
};

// const actions = {
//   addItem:
//     (uid: string): Action<State> =>
//     ({ setState, getState }) => {
//       setState({
//         items: [...getState().items, uid],
//       });
//     },
//   removeItem:
//     (index: number): Action<State> =>
//     ({ setState, getState }) => {
//       const tmp = [...getState().items];
//       tmp.splice(index, 1);
//       console.log(tmp);
//       setState({ items: tmp });
//     },
//   clearCart:
//     (): Action<State> =>
//     ({ setState, getState }) => {
//       setState({ items: [] });
//     },
// };

// const Store = createStore<State, Actions>({
//   initialState,
//   actions,
// });

// export const useShoppingCart = createHook(Store);
// export const ShoppingCartContainer = createContainer(Store);

const context = createContext<[State, any]>([{ items: [] }, undefined]);

export const useShoppingCart = (): [State, any] => {
  const [state, setState] = useContext(context);

  return [
    state,
    {
      addItem: (uid: string) => {
        setState({
          items: [...state.items, uid],
        });
      },
      removeItem: (index: number) => {
        const tmp = [...state.items];
        tmp.splice(index, 1);
        console.log(tmp);
        setState({ items: tmp });
      },
      clearCart: () => {
        setState({ items: [] });
      },
    },
  ];
};

export const ShoppingCartContainer = ({ children, initial }: any) => {
  const [state, setState] = useState<State>(initialState);

  return (
    <context.Provider value={[state, setState]}>{children}</context.Provider>
  );
};
